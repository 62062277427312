import React from 'react';
import { Carousel } from "antd";

export const ProductCard = ({ product, handleClick }) => {
    const images = [];

    let i = 1;
    while (i <= product.images_number) {
        images.push(`img/products/card/${product.images_path}/${i}.svg`);
        i++;
    }

    return (
        <div className="service-card">
            <div className="hover-bg">
                <div className='hover-text' onClick={() => handleClick(product)}>
                    <h4>VER FOTOS</h4>
                </div>
                <Carousel
                    autoplay
                    infinite
                    pauseOnDotsHover={false}
                    pauseOnFocus={false}
                    pauseOnHover={false}
                    className="service-card-carousel"
                    dots={false}
                >
                    {images.map((image, i) => <img key={`${image}-${i}`} className="service-card-image" src={image} alt={image} />)}
                </Carousel>
            </div>
            <div className="service-desc">
                <h3>{product.name}</h3>
                <h5>{product.ref}</h5>
                <p >{product.desc}</p>
            </div>
        </div>
    );
}