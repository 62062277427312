import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Products } from "./components/products";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import { GalleryModal } from "./components/productGalleryModal";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedProduct(null);
  };
  const handleModalOpen = (newSelectedProduct) => {
    setModalVisible(true);
    setSelectedProduct(newSelectedProduct);
  };

  return (
    <div>
      <GalleryModal isOpen={modalVisible} product={selectedProduct} onClose={handleModalClose} />
      <Navigation modalVisible={modalVisible} />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Products data={landingPageData.Services} handleProductSelection={handleModalOpen} />
      <Testimonials data={landingPageData.Testimonials} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
