export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Entre em Contato</h2>
              </div>
              <div id="map-frame">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14134.827262195917!2d-54.1205676!3d-27.6645412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6f8f51f926aa809!2sTreli%C3%A7as%20Santa%20Clara!5e0!3m2!1spt-BR!2sbr!4v1591107229818!5m2!1spt-BR!2sbr"
                        width="600" height="450" frameBorder="2"
                        style={{border: "2px", maxWidth: "90vw", maxHeight: "90vw"}} allowFullScreen="" 
                        aria-hidden="false" tabIndex="0"
                        id="map-content" title="map-iframe">
                </iframe>
            </div>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Informações de Contato</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Endereço
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-whatsapp'></i> WhatsApp
                </span>{' '}
                {props.data ? (
                  <a href="http://api.whatsapp.com/send?1=pt_BR&phone=5555984090445" rel="noreferrer" target="_blank">
                    {props.data.whatsapp}
                  </a>
                ) : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                <a href={`mailto:${props.data ? props.data.email : ''}`}>
                  {props.data ? props.data.email : 'loading'}
                </a>
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'} rel="noreferrer" target="_blank">
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'} rel="noreferrer" target="_blank">
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'} rel="noreferrer" target="_blank">
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 <a href="https://www.linkedin.com/in/miguel-martins-dos-santos-1843b9140" rel="noreferrer" target="_blank">Miguel dos Santos</a><br></br>{' '}
            <a href='http://github.com/miguelmdsdp' rel='noreferrer' target='_blank'>
              @MiguelMDSDP
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
