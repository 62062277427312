export const Navigation = (props) => {
  return (
    props.modalVisible ? null : (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='#page-top'>
              Rural Santa Clara
            </a>{' '}
          </div>

          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              <li>
                <a href='#about' className='page-scroll'>
                  Sobre Nós
                </a>
              </li>
              <li>
                <a href='#services' className='page-scroll'>
                  Produtos
                </a>
              </li>
              <li>
                <a href='#testimonials' className='page-scroll'>
                  Depoimentos
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll'>
                  Contato
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  )
}
