import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';

export const GalleryModal = ({ product, isOpen, onClose }) => {
    const [images, setImages] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        if (product) {
            let i = 1;
            const newImages = [];
            while (i <= product.images_number) {
                newImages.push(`img/products/gallery/${product.images_path}/${i}.jpeg`);
                i++;
            }
            setImages(newImages);
        } else {
            setImages([]);
        }
    }, [product]);

    const handleNext = () => {
        setPhotoIndex((photoIndex + images.length - 1) % images.length);
    }

    const handlePrev = () => {
        setPhotoIndex((photoIndex + 1) % images.length);
    }

    return isOpen && <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={onClose}
        onMovePrevRequest={handlePrev}
        onMoveNextRequest={handleNext}
    />
}