import { useEffect, useState } from "react"
import { ProductCard } from "./productCard"

export const Products = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Nossos produtos</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className={windowWidth >= 1700 ? 'col-md-4' : windowWidth >= 1125 ? 'col-md-6' : 'col-md-8'}>
                <ProductCard product={d} handleClick={props.handleProductSelection} />
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
